<template>
  <div>
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        v-if="hasRight('DEVICE_WRITE')"
        v-bind="$config.buttonAttrs.floating"
        :to="{ name: 'NewDoor' }"
        title="Hozzáadás"
      >
        <v-icon dark>mdi-plus</v-icon>
      </v-btn>
    </div>

    <h1 class="py-6">Ajtók</h1>

    <v-expansion-panels class="mb-4" :mandatory="!!Object.values(search).find((e) => e !== '')">
      <v-expansion-panel>
        <v-expansion-panel-header>
          <span>
            <v-icon left>mdi-filter</v-icon>
            Szűrés
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row class="mt-2">
            <v-col :cols="12" :sm="4" :md="2">
              <v-text-field
                v-model="search.name"
                @input="searchEventHandler"
                label="Megnevezés"
                clearable
                hide-details
                class="my-4 my-sm-0"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="2">
              <v-autocomplete
                v-model="search.building_id"
                label="Épület"
                :items="buildings"
                item-text="name"
                item-value="id"
                clearable
                hide-details
                placeholder="Összes"
                @change="buildingChange"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="2">
              <v-autocomplete
                v-model="search.building_level_id"
                label="Épület szint"
                :items="buildingLevels"
                item-text="name"
                item-value="id"
                clearable
                hide-details
                @change="searchEventHandler"
                :placeholder="search.building_id ? 'Összes szint' : 'Épület kötelező'"
                :readonly="!search.building_id"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="2">
              <v-autocomplete
                v-model="search.reader_id"
                label="Olvasó"
                :items="readers"
                item-text="name"
                item-value="element_id"
                clearable
                hide-details
                placeholder="Összes"
                @change="searchEventHandler"
              />
            </v-col>
            <v-col :cols="12" :sm="4" :md="2">
              <v-autocomplete
                v-model="search.controller_id"
                label="Kontroller"
                :items="controllers"
                item-text="name"
                item-value="element_id"
                clearable
                hide-details
                placeholder="Összes"
                @change="searchEventHandler"
              />
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-card>
      <v-data-table
        :headers="dataTable.headers"
        :items="dataTable.items"
        :server-items-length="dataTable.itemsLength"
        :options.sync="dataTable.options"
        :footer-props="$config.dataTableFooterProps"
        :loading="loading"
      >
        <template #[`item.k`]="{ item }"
          >{{ item.x_coord }}, {{ item.y_coord }}</template
        >
        <template
          #[`item.actions`]="{ item }"
        >
          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            fab
            small
            color="secondary"
            dark
            text
            class="rounded-sm"
            :to="`/devices/doors/edit/${item.element_id}`"
            title="Módosítás"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            v-if="hasRight('DEVICE_WRITE')"
            fab
            small
            color="secondary"
            dark
            text
            class="rounded-sm"
            @click="deleteDoor(item)"
            title="Törlés"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<script>
import { dataTablePage } from '@/mixins';

export default {
  mixins: [dataTablePage],

  data() {
    return {
      routeName: 'DoorList',
      fetchItems: this.fetchDoors,
      dataTable: {
        options: {
          sortBy: ['door_name'],
        },
        headers: [
          { text: "Megnevezés", value: "door_name" },
          { text: "Kontroller", value: "controller_name" },
          { text: "Olvasó", value: "reader_name" },
          { text: "Épület", value: "building_name" },
          { text: "Épület szint", value: "building_level_name" },
          { text: "Koordináta", sortable: false, value: "k" },
          { text: "", value: "actions", sortable: false, align: "end" },
        ],
      },
      search: {
        building_id: 0,
        building_level_id: 0,
        controller_id: 0,
        reader_id: 0,
        name: "",
      },
      doors: [],
      controllers: [],
      readers: [],
      buildings: [],
      buildingLevels: [],
    };
  },

  mounted() {
    this.fetchDevices();
    this.fetchBuildings();
  },

  methods: {
    parseSearchQueries() {
      return {
        name: this.$route.query.name,
        controller_id: Number(this.$route.query.controller_id)
      };
    },

    async deleteDoor(item) {
      const confirm = await this.$dialog.confirm({
        type: 'error',
        text: `<b>${item.name}</b> ajtó törlésére készül!<br>Folytatja?`,
        title: 'Figyelem',
      });

      if (!confirm) return;

      try {
        const response = await this.$http.get(`doors/delete/${item.element_id}`);
        this.fetchItems();
        if (response.status === 'OK') {
          this.$dialog.notify.info('Az ajtó törölve');
        }
      } catch (e) {
        console.error(e);
      }
    },

    buildingChange() {
      if (this.search.building_id == 0) {
        this.search.building_level_id = 0;
        return;
      }

      this.fetchBuildingLevels();
      this.searchEventHandler();
    },

    async fetchDevices() {
      try {
        const response = await this.$http.post("elements/list", {
          model_names: [ 'Controllers', 'Readers' ],
        });
        for (const element of response.elements) {
          switch(element.type.model_name) {
            case 'Controllers':
             this.controllers.push(element);
             break;
            case 'Readers':
              this.readers.push(element);
              break;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBuildings() {
      try {
        const response = await this.$http.post('buildings/list');
        this.buildings = response.buildings;
      } catch (e) {
        console.error(e);
      }
    },

    async fetchBuildingLevels() {
      try {
        const response = await this.$http.post(
          'building-levels/list',
          { building_id: this.search.building_id }
        );
        this.buildingLevels = response.building_levels;

        this.search.building_level_id = 0;
        this.buildingLevels = tmp;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchDoors() {
      this.loading = true;
      try {
        const response = await this.$http.post('doors/list', this.options);
        this.dataTable.items = response.doors;
        this.dataTable.itemsLength = response.doors_count;
        this.dataTable.options.page = Number(this.$route.query.page) || 1;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    searchQueries() {
      return {
        name: this.search.name || '',
        building_id: this.search.building_id || 0,
        building_level_id: this.search.building_level_id || 0,
        reader_id: this.search.reader_id || 0,
        controller_id: this.search.controller_id || 0,
      };
    },
  },
};
</script>
